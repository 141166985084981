<template>
  <div class="page-header-title">
    <div class="row m-b-10">
      <div class="col-6"><h3>{{ $t('currentSenderNames') }}</h3></div>
      <div v-if="!isLoading && isSenderNamesExist" class="col-6 text-right">
        <button @click.prevent="goToPurchase" class="btn btn-primary">{{ $t('buyNewPackages') }}</button>
      </div>
    </div>
  </div>
  <spinner-component v-if="isLoading" item-class="table-spinner-layout"></spinner-component>
  <div v-if="!isLoading">
    <current-sender-names-table
      v-if="isSenderNamesExist"
      :onAfterDelete="fetchData"
      :list="senderNames"
    />
    <div v-if="!isSenderNamesExist">
      <p>{{ $t('noPurchaseText') }}</p>
      <p><button @click.prevent="goToPurchase" class="btn btn-primary">{{ $t('buyPackages') }}</button></p>
    </div>
  </div>
</template>

<script>
import { ref, defineAsyncComponent, onMounted, computed } from 'vue'
import { getCurrentSenderNames } from '@/application/services/sender-name.js'
import { useRouter } from 'vue-router'

export default {
  name: 'CurrentSenderNamesPage',
  components: {
    'spinner-component': defineAsyncComponent(() => import('@/application/components/layout/SpinnerComponent.vue')),
    'current-sender-names-table': defineAsyncComponent(() => import('@/application/components/elements/CurrentSenderNamesTable.vue')),
  },
  setup() {
    const router = useRouter()
    const senderNames = ref([])
    const isLoading = ref(true)
    const isSenderNamesExist = computed(() => {
      return senderNames.value.length > 0
    })
    const fetchData = async() => {
      isLoading.value = true
      const { data } = await getCurrentSenderNames()
      isLoading.value = false
      senderNames.value = data.sender_names
    }

    onMounted(fetchData)
    return {
      senderNames,
      isLoading,
      fetchData,
      isSenderNamesExist,
      goToPurchase: () => {
        router.push({ name: 'purchase' })
      },
    }
  },
}
</script>
